<template>
  <el-option v-bind="{ ...$attrs, ...props }" class="ui-option">
    <template #default>
      <slot v-if="useCustomSlot" name="default"></slot>
      <div v-else class="ui-option__content">
        <span>{{ label ?? value }}</span>
        <CheckmarkIcon filled class="ui-option__selected-icon" />
      </div>
    </template>
  </el-option>
</template>
<script setup lang="ts">
import CheckmarkIcon from '@base/assets/img/svg/checkmark-circle.svg'

const props = defineProps({
  /**
   * The value of the option
   */
  value: {
    type: [String, Number, Boolean, Object],
    required: true,
  },
  /**
   * The label of the option, same as value if omitted
   */
  label: {
    type: [String, Number],
  },
  /**
   * Whether the option is disabled
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * To render the option label with custom component
   */
  useCustomSlot: {
    type: Boolean,
    default: false,
  },
})
</script>
<style lang="postcss" scoped>
.ui-option.el-select-dropdown__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 0.5rem 0.75rem; /* 8px 12px */
  font-family: inherit;
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.375rem; /* 22px */
  color: var(--brand-color-text-secondary);
  border-radius: 4.0625rem; /* 65px */

  &[aria-selected='true'] {
    & .ui-option__selected-icon {
      display: block;
    }
  }
  &.is-hovering {
    color: var(--brand-color-text-secondary);
    background-color: var(--brand-color-base-tertiary);
  }
}
.ui-option__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
}
.ui-option__selected-icon {
  display: none;
  width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
  margin: 0;
}
</style>
